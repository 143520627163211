<template>
  <div>
    <Search ref="search" class="search-layout" @setSelectStr="setSelectStr">
      <div slot="left" class="search-left">
        <van-icon
          name="arrow-left"
          size="24"
          @click="$router.go(-1)"
          color="#9B9B9B"
        />
      </div>
      <div slot="right" class="search-right">
        <van-button
          type="danger"
          round
          color="linear-gradient(to right, #ff6034, #ee0a24)"
          @click="setSelectGoods"
        >
          搜索
        </van-button>
      </div>
    </Search>
    <div class="usable-gold">
      <span class="font-set" id="closeClass">
        可用消费金：￥{{ twoNum(moneyInfo.consume_money) }}
      </span>
      <div class="consumption-detail" @click="goToUrl()">消费金明细</div>
    </div>
    <!-- 分类筛选 -->
    <van-sticky :offset-top="64" @scroll="changeScroll">
      <van-dropdown-menu :class="{ 'tab-color': showBack }">
        <van-dropdown-item
          ref="menuList"
          :title="menuTitle"
          title-class="font-weight"
        >
          <Class classType="2" @setClass="setClass"></Class>
        </van-dropdown-item>
        <van-dropdown-item
          :options="option2"
          v-model="shopSort"
          @open="onOpen"
          @close="onClose"
          title-class="font-weight"
        />
      </van-dropdown-menu>
    </van-sticky>
    <div class="content-layout">
      <GoodsShowLabel
        ref="goodsList"
        :sendType="2"
        :goodName="goodName"
        :classId="classId"
        :orderBy="shopSort"
        :isExpense="1"
      ></GoodsShowLabel>
    </div>
  </div>
</template>

<script>
import Search from "@/components/search/search";
import GoodsShowLabel from "@/components/goods-show/goods-show-label";
import Class from "./components/class";
import { getMemberInfo } from "@/api/member";
export default {
  name: "gold-list",
  components: {
    Search,
    GoodsShowLabel,
    Class,
  },
  data() {
    return {
      goodName: "",
      classId: 0,
      showBack: false,
      showClass: false,
      menuTitle: "全部",
      shopClass: "",
      shopSort: 1,
      option2: [
        { text: "综合排序", value: 1 },
        { text: "销量优先", value: 2 },
        { text: "评分优先", value: 3 },
        { text: "价格从低到高", value: 4 },
        { text: "价格从高到低", value: 5 },
      ],
      active: false,
      moneyInfo: {
        consume_money: 0,
      },
    };
  },
  watch: {},
  async mounted() {
    if (this.$store.state.token && this.$store.state.m_id) {
      const res = await getMemberInfo();
      if (res.code * 1 === 1) {
        this.moneyInfo = res.data;
      }
    }
  },
  methods: {
    goToUrl() {
      //页面跳转
      this.$router.push({ name: "remaining-sum", query: { moneyType: 2 } });
    },
    twoNum(num) {
      // 获取两位小数
      return (num * 1).toFixed(2);
    },
    setSelectStr(value) {
      this.goodName = value;
    },
    setSelectGoods() {
      this.reloadGoods();
    },
    reloadGoods() {
      let _that = this;
      setTimeout(function () {
        _that.$refs.goodsList.reloadGoods();
      }, 50);
    },
    setClass(classInfo) {
      this.$refs.menuList.toggle(false);
      this.classId = classInfo.id;
      this.menuTitle = classInfo.class_name;
      // window.eval(document.getElementById("closeClass").getAttribute("onclick"))
      this.reloadGoods();
    },
    onOpen() {
      this.showBack = true;
    },
    onClose() {
      this.showBack = false;
    },
    changeScroll(obj) {
      obj.scrollTop >= 66 ? (this.showBack = true) : (this.showBack = false);
    },
  },
};
</script>

<style lang="less" scoped>
.tab-color {
  // 动态背景色
  background-color: #fff;
  transition: all 0.1s;
}
.search-layout {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 8000;
  border-bottom: 1px solid #ededed;
}
// 可用消费金
.usable-gold {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 12px;
  margin-top: 58px;
  background-color: #fff;
  .consumption-detail {
    width: 84px;
    height: 28px;
    background: #ffffff;
    border: 1px solid #e61f18;
    opacity: 1;
    border-radius: 18px;
    text-align: center;
    line-height: 28px;
    color: #e61f18;
    font-size: 13px;
    &:active {
      background-color: rgb(243, 243, 243);
    }
  }
  .van-button {
    height: 28px;
  }
  .font-set {
    font-size: 18px;
    color: #6f6f6f;
  }
}
// vant组件样式
/deep/.van-dropdown-item__content {
  min-height: 100%;
}
/deep/.van-dropdown-menu__bar {
  background-color: transparent;
  box-shadow: none;
}
/deep/.van-popup {
  background-color: #f9f9f9;
}
/deep/.font-weight {
  font-weight: 500;
  font-size: 18px;
}
/deep/.van-sticky--fixed {
  top: 58px !important;
}
/deep/.van-cell__title {
  font-size: 16px;
}
</style>
